import { apiSlice } from "../../app/api/apiSlice"

export const instituteApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        createInstitute: builder.mutation({
            query: ({ institute, instituteSOP, instituteManagerSOP }) => ({
                url: '/backend/insti/institute',
                method: 'POST',
                body: { institute, instituteSOP, instituteManagerSOP }
            }),
            invalidatesTags: [
                { type: 'Institute', id: "LIST" }
            ]
        }),
        editInstitute: builder.mutation({
            query: ({ oldInstitute, newInstitute, newInstituteSOP, newInstituteManagerSOP }) => ({
                url: '/backend/insti/institute',
                method: 'PATCH',
                body: { oldInstitute, newInstitute, newInstituteSOP, newInstituteManagerSOP }
            }),
            invalidatesTags: [
                { type: 'Institute', id: "LIST" }
            ]
        }),
        getInstitutes: builder.query({
            query: () => '/backend/insti/institutes',
        }),
        deleteInstitute: builder.mutation({
            query: (institute) => ({
                url: `/backend/insti/institute`,
                method: 'DELETE',
                body: { institute }
            }),
            invalidatesTags: [
                { type: 'Institute', id: "LIST" }
            ]
        }),
    }),
})

export const {
    useCreateInstituteMutation,
    useEditInstituteMutation,
    useGetInstitutesQuery,
    useDeleteInstituteMutation,
} = instituteApiSlice
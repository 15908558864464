import { useState, useEffect, useRef } from "react"
import { useUpdateUserMutation, useDeleteUserMutation } from "./usersApiSlice"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faTrashCan } from "@fortawesome/free-solid-svg-icons"
import { ROLES } from "../../config/roles"
import { Container, Row, Col, Card } from 'react-bootstrap'
import Modal from '../../components/Modal'

const USER_REGEX = /^[A-z0-9!@#$%]{6,30}$/
const FULLNAME_REGEX = /^[A-z0-9!@#$% ]{6,255}$/
const PWD_REGEX = /^[A-z0-9!@#$%]{6,12}$/

const EditUserForm = ({ user }) => {

    const [updateUser, {
        isLoading,
        isSuccess,
        // eslint-disable-next-line
        isError,
        error
    }] = useUpdateUserMutation()

    const [deleteUser, {
        isSuccess: isDelSuccess,
        // eslint-disable-next-line
        isError: isDelError,
        error: delerror
    }] = useDeleteUserMutation()

    const navigate = useNavigate()

    useEffect(() => {
        if (error) {
            setErrMsg(error?.data?.message || 'Failed to save user.');
            setShowModal(true);
        }
    }, [error]);

    useEffect(() => {
        if (delerror) {
            setErrMsg(delerror?.data?.message || 'Unknown error.');
            setShowModal(true);
        }
    }, [delerror]);

    const [username, setUsername] = useState(user.username)
    const [validUsername, setValidUsername] = useState(false)
    const [password, setPassword] = useState('')
    const [validPassword, setValidPassword] = useState(false)
    const [fullname, setFullname] = useState(user.fullname)
    const [validFullname, setValidFullname] = useState(false)
    const [roles, setRoles] = useState(user.roles)
    const [active, setActive] = useState(user.active)
    const [annotationviewer, setAnnotationviewer] = useState(user.annotationviewer)
    const [sopAccess, setSopAccess] = useState(user?.sopaccess || false);
    const [sopAccessManager, setSopAccessManager] = useState(user?.sopaccessmanager || false);

    const usernameRef = useRef(null);
    const fullnameRef = useRef(null);

    const [errMsg, setErrMsg] = useState('');
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        setValidUsername(USER_REGEX.test(username))
    }, [username])

    useEffect(() => {
        setValidFullname(FULLNAME_REGEX.test(fullname))
    }, [fullname])

    useEffect(() => {
        setValidPassword(PWD_REGEX.test(password))
    }, [password])

    useEffect(() => {
        if (isSuccess || isDelSuccess) {
            setUsername('')
            setFullname('')
            setPassword('')
            setRoles([])
            navigate('/dash/users')
        }

    }, [isSuccess, isDelSuccess, navigate])

    const handleCloseModal = () => {
        setShowModal(false);
        setErrMsg('');
    };

    const onUsernameChanged = e => {
        const newUsername = e.target.value;
        setUsername(newUsername);
        setValidUsername(USER_REGEX.test(newUsername));
        if (!USER_REGEX.test(newUsername)) {
            setErrMsg('Minimum 6 - 30 characters and no space.');
            usernameRef.current.focus();
        } else {
            setErrMsg('');
        }
    };

    const onFullnameChanged = e => {
        const newFullname = e.target.value;
        setFullname(newFullname);
        setValidFullname(FULLNAME_REGEX.test(newFullname));
        if (!FULLNAME_REGEX.test(newFullname)) {
            setErrMsg('Minimum 6 - 255 characters.');
            fullnameRef.current.focus();
        } else {
            setErrMsg('');
        }
    };

    const onPasswordChanged = e => setPassword(e.target.value)

    const onRolesChanged = e => {
        const values = Array.from(
            e.target.selectedOptions,
            (option) => option.value
        )
        setRoles(values)
    }

    const onActiveChanged = () => setActive(prev => !prev)

    const onAnnotationviewerChanged = () => setAnnotationviewer(prev => !prev)

    let canSave
    if (password) {
        canSave = [roles.length, validFullname, validUsername, validPassword].every(Boolean) && !isLoading
    } else {
        canSave = [roles.length, validFullname, validUsername].every(Boolean) && !isLoading
    }

    const onSaveUserClicked = async (e) => {
        if (password && canSave) {
            await updateUser({ id: user.id, username, fullname, password, roles, active, annotationviewer, sopAccess, sopAccessManager })
        } else {
            await updateUser({ id: user.id, username, fullname, roles, active, annotationviewer, sopAccess, sopAccessManager })
        }
    }

    const onDeleteUserClicked = async (e) => {
        e.preventDefault();
        const confirmDelete = window.confirm("Are you sure you want to delete the user?");
        if (confirmDelete) {
            await deleteUser({ id: user.id })
        }
    }

    const onSOPAccessChanged = () => setSopAccess(prev => !prev);
    const onSOPAccessManagerChanged = () => setSopAccessManager(prev => !prev);

    const options = Object.values(ROLES).map(role => {
        return (
            <option
                key={role}
                value={role}

            > {role}</option >
        )
    })


    const content = (
        <>
            <Container style={{ paddingTop: '6rem', paddingBottom: '7rem' }}>
                <Row className="justify-content-md-center">
                    <Col xs={12} md={8} lg={4}>
                        <form onSubmit={e => e.preventDefault()}>
                            <Card className="mb-3 darktEditUsercontent">
                                <Card.Body>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <button
                                            title="Save"
                                            className="user-edit-save-button"
                                            onClick={onSaveUserClicked}
                                            disabled={!canSave || isLoading}
                                        >
                                            <FontAwesomeIcon icon={faSave} /> {isLoading ? 'Saving...' : ``}
                                        </button>
                                        <button
                                            title="Delete"
                                            className="user-delete-button"
                                            onClick={onDeleteUserClicked}
                                        >
                                            <FontAwesomeIcon icon={faTrashCan} />
                                        </button>
                                    </div>
                                    <label style={{ fontWeight: 'bold', display: 'block', marginTop: '10px' }}>Edit User : </label>

                                    <input
                                        id="username"
                                        name="username"
                                        title="Username"
                                        type="text"
                                        placeholder="UserId"
                                        style={{ borderRadius: '6px', padding: '4px 8px', border: '2px solid #ccc', marginRight: '10px', display: 'block', marginTop: '10px' }}
                                        autoComplete="off"
                                        value={username}
                                        ref={usernameRef}
                                        onChange={onUsernameChanged}
                                        className={!validUsername ? 'error-input' : ''}
                                    />
                                    {!validUsername && <span className="error-message">Minimum 6 - 30 characters no space.</span>}

                                    <input
                                        id="fullname"
                                        name="fullname"
                                        title="Fullname"
                                        type="text"
                                        placeholder="Fullname"
                                        style={{ borderRadius: '6px', padding: '4px 8px', border: '2px solid #ccc', marginRight: '10px', display: 'block', marginTop: '10px' }}
                                        autoComplete="off"
                                        value={fullname}
                                        onChange={onFullnameChanged}
                                        ref={fullnameRef}
                                        className={!validFullname ? 'error-input' : ''}
                                    />
                                    {!validFullname && <span className="error-message">Minimum 6 - 255 characters.</span>}

                                    <input
                                        id="password"
                                        name="password"
                                        title="Password"
                                        type="password"
                                        placeholder="Password [6-12 chars no space]"
                                        style={{ borderRadius: '6px', padding: '4px 8px', border: '2px solid #ccc', marginRight: '10px', display: 'block', marginTop: '10px' }}
                                        value={password}
                                        onChange={onPasswordChanged}
                                    />

                                    <label style={{ fontWeight: 'bold', display: 'block', marginTop: '10px' }}>Active :
                                        <input
                                            id="user-active"
                                            name="user-active"
                                            title="User status"
                                            type="checkbox"
                                            checked={active}
                                            onChange={onActiveChanged}
                                            style={{ marginLeft: '5px' }}
                                        /></label>

                                    <label style={{ fontWeight: 'bold', display: 'block', marginTop: '10px' }}>Annotation access :
                                        <input
                                            id="user-active"
                                            name="user-active"
                                            title="User status"
                                            type="checkbox"
                                            checked={annotationviewer}
                                            onChange={onAnnotationviewerChanged}
                                            style={{ marginLeft: '5px' }}
                                        /></label>

                                    <label style={{ fontWeight: 'bold', display: 'block', marginTop: '10px' }}>Consultant SOP:
                                        <input
                                            id="user-sop"
                                            name="user-sop"
                                            title="SOP access consultant"
                                            type="checkbox"
                                            checked={sopAccess}
                                            onChange={onSOPAccessChanged}
                                            style={{ marginLeft: '5px' }}
                                        />
                                    </label>

                                    <label style={{ fontWeight: 'bold', display: 'block', marginTop: '10px' }}>Manager SOP:
                                        <input
                                            id="user-sop"
                                            name="user-sop"
                                            title="SOP access manager"
                                            type="checkbox"
                                            checked={sopAccessManager}
                                            onChange={onSOPAccessManagerChanged}
                                            style={{ marginLeft: '5px' }}
                                        />
                                    </label>

                                    <label style={{ fontWeight: 'bold', display: 'block', marginTop: '10px' }}>Select Authorization: </label>
                                    <select
                                        id="roles"
                                        name="roles"
                                        title="Roles"
                                        multiple={true}
                                        size="3"
                                        value={roles}
                                        onChange={onRolesChanged}
                                        style={{ display: 'block', marginTop: '10px' }}
                                    >
                                        {options}
                                    </select>

                                </Card.Body>
                            </Card>
                        </form>
                    </Col>
                </Row>
            </Container>
            <Modal show={showModal} onClose={handleCloseModal}>
                <p>{errMsg}</p>
            </Modal>
        </>

    )

    return content
}
export default EditUserForm
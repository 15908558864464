import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom"
import { useAddNewReportMutation } from "./reportsApiSlice"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMicrophone, faMicrophoneSlash, faSave } from "@fortawesome/free-solid-svg-icons"
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import useAuth from "../../hooks/useAuth";
import { useGetTemplateContentQuery, useGetTemplatesQuery } from "../temp/tempApiSlice";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { useGetInstitutesQuery } from '../insti/instituteApiSlice'
import { Container, Row, Col } from 'react-bootstrap';
import Modal from '../../components/Modal';

const modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ font: [] }],
        [{ size: [] }],
        [{ align: '' }, { align: 'center' }, { align: 'right' }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
        ],
        ["link", "image", "video"],
    ],
};

const TITLE_REGEX = /^[\w\s\S]{3,255}$/
const ACCESSION_REGEX = /^[\w\S]{4,30}$/

const NewReportForm = ({ users }) => {

    const commands = [
        {
            command: 'delete',
            callback: () => {
                if (window.confirm('Are you sure you want to delete all report data?')) {
                    setSaveReports([]);
                    SpeechRecognition.stopListening();
                    resetTranscript();
                    localStorage.clear();
                    setIsAutoListening(false);
                }
                else {
                    SpeechRecognition.stopListening();
                    resetTranscript();
                }
            }
        },
        {
            command: 'next line',
            callback: () => {
                stopListening();
                if (isAutoListening) {
                    setTimeout(startListening, 500);
                }
            }
        },
        {
            command: 'title *',
            callback: (title) => {
                let upperCaseText = title.toUpperCase();
                const newValue = saveReports + `<h1 class="ql-align-center"><strong><u>${upperCaseText}</u></strong></h1><br/>`;
                setSaveReports(newValue);
                localStorage.setItem(`${username}_transcript`, newValue);
                stopCommand();
                if (isAutoListening) {
                    setTimeout(startListening, 500);
                }
            }
        },
        {
            command: 'history',
            callback: () => {
                stopListening();
                const newValue = saveReports + '<br/><h2><strong>Clinical History: </strong></h2>';
                setSaveReports(newValue);
                localStorage.setItem(`${username}_transcript`, newValue);
                if (isAutoListening) {
                    setTimeout(startListening, 500);
                }
            }
        },
        {
            command: 'technique',
            callback: () => {
                stopListening();
                const newValue = saveReports + '<br/><h2><strong>Technique: </strong></h2>';
                setSaveReports(newValue);
                localStorage.setItem(`${username}_transcript`, newValue);
                if (isAutoListening) {
                    setTimeout(startListening, 500);
                }
            }
        },
        {
            command: 'findings',
            callback: () => {
                stopListening();
                const newValue = saveReports + '<br/><h2><strong>Findings: </strong></h2>';
                setSaveReports(newValue);
                localStorage.setItem(`${username}_transcript`, newValue);
                if (isAutoListening) {
                    setTimeout(startListening, 500);
                }
            }
        },
        {
            command: 'impression',
            callback: () => {
                stopListening();
                const newValue = saveReports + '<br/><h2><strong>Impression: </strong></h2>';
                setSaveReports(newValue);
                localStorage.setItem(`${username}_transcript`, newValue);
                if (isAutoListening) {
                    setTimeout(startListening, 500);
                }
            }
        },
        {
            command: 'advice',
            callback: () => {
                stopListening();
                const newValue = saveReports + '<br/><h2><strong>Advice: </strong></h2>';
                setSaveReports(newValue);
                localStorage.setItem(`${username}_transcript`, newValue);
                if (isAutoListening) {
                    setTimeout(startListening, 500);
                }
            }
        },
        {
            command: 'underline *',
            callback: (underline) => {
                const newValue = saveReports + `<u>${underline}</u>. `;
                setSaveReports(newValue);
                localStorage.setItem(`${username}_transcript`, newValue);
                stopCommand();
                if (isAutoListening) {
                    setTimeout(startListening, 500);
                }
            }
        },
        {
            command: 'bold face *',
            callback: (point) => {
                const newValue = saveReports + `<strong>${point}</strong>. `;
                setSaveReports(newValue);
                localStorage.setItem(`${username}_transcript`, newValue);
                stopCommand();
                if (isAutoListening) {
                    setTimeout(startListening, 500);
                }
            }
        },
    ];

    const location = useLocation();
    const { username, isManager, isAdmin, sopaccess, sopaccessmanager } = useAuth()

    const [saveReports, setSaveReports] = useState(localStorage.getItem(`${username}_transcript`) || []);
    const [selectedFile, setSelectedFile] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [isAutoListening, setIsAutoListening] = useState(false);
    const textAreaRef = useRef(null);
    const quillRef = useRef(null);
    const titleRef = useRef(null);
    const accessionRef = useRef(null);
    const [fileContentAppended, setFileContentAppended] = useState(false);
    const [currentFileContent, setCurrentFileContent] = useState('');
    const [selectedInstitute, setSelectedInstitute] = useState('');
    const [searchInstituteTerm, setSearchInstituteTerm] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [dob, setDob] = useState('');
    const [sex, setSex] = useState('');
    const [selectedInstituteSOP, setSelectedInstituteSOP] = useState('');
    const [selectedInstituteSOPManager, setSelectedInstituteSOPManager] = useState('');


    const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition({ commands });


    const [addNewReport, {
        isLoading,
        isSuccess,
        error
    }] = useAddNewReportMutation()

    const {
        data: files,
        // eslint-disable-next-line
        error: filesError,
        // eslint-disable-next-line
        isLoading: filesLoading,
    } = useGetTemplatesQuery();

    const {
        data: fileContent,
        error: fileContentError,
        isLoading: fileContentLoading,
        refetch: refetchFileContent,
    } = useGetTemplateContentQuery(selectedFile, { skip: !selectedFile });

    useEffect(() => {
        if (fileContent && !fileContentLoading && !fileContentError) {
            setCurrentFileContent(fileContent.data);
        }
    }, [fileContent, fileContentLoading, fileContentError]);

    useEffect(() => {
        if (currentFileContent && !fileContentAppended) {
            const newValue = saveReports + currentFileContent;
            setSaveReports(newValue);
            localStorage.setItem(`${username}_transcript`, newValue);
            setFileContentAppended(true);
            setCurrentFileContent('')
        }
    }, [currentFileContent, fileContentAppended, saveReports, username]);

    useEffect(() => {
        const handleBeforeUnload = () => {
            localStorage.removeItem(`${username}_transcript`);
        };
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [username]);


    const navigate = useNavigate()

    const [title, setTitle] = useState('');
    const [accessionno, setAccessionno] = useState('');
    const [validTitle, setValidTitle] = useState(false)
    const [validAccessionno, setValidAccessionno] = useState(false)
    const userSet = users.find(user => user.username === username);
    const [userId, setUserId] = useState(userSet.id);
    const [access_Ids, setAccess_Ids] = useState([]);
    const [priority, setPriority] = useState('Routine');
    const [modality, setModality] = useState('');

    useEffect(() => {
        if (!(localStorage.getItem(`${username}_transcript`))) {
            localStorage.setItem(`${username}_transcript`, "");
            setSaveReports(localStorage.getItem(`${username}_transcript`))
        }

        if (isSuccess) {
            setTitle('')
            setAccessionno('')
            setSaveReports('')
            localStorage.removeItem(`${username}_transcript`)
            setUserId('')
            navigate('/dash/report')
        }
    }, [isSuccess, username, navigate])

    useEffect(() => {
        return () => {
            localStorage.removeItem(`${username}_transcript`);
        };
    }, [location, username]);

    useEffect(() => {
        if (fileContentError) {
            setErrMsg('Failed to fetch file content.');
            setShowModal(true);
        }
    }, [fileContentError]);

    useEffect(() => {
        if (error) {
            setErrMsg(error?.data?.message || 'Failed to save report.');
            setShowModal(true);
        }
    }, [error]);

    const { data: institutes } = useGetInstitutesQuery();

    if (!browserSupportsSpeechRecognition) {
        return <span>Browser doesn't support speech recognition.</span>;
    }

    const startListening = () => {
        resetTranscript();
        SpeechRecognition.startListening({ continuous: true });
    };

    const stopListening = () => {
        SpeechRecognition.stopListening();

        let modifiedTranscript = transcript.replace(/( stop| full stop| period\s*)+/g, '. ');
        modifiedTranscript = modifiedTranscript.replace(/(next\s*line\s*)+/g, '');
        modifiedTranscript = modifiedTranscript.replace(/(comma\s*)+/g, ', ');
        modifiedTranscript = modifiedTranscript.replace(/(hyphen\s*)+/g, '-');
        modifiedTranscript = modifiedTranscript.replace(/( underscore\s*)+/g, '_');
        modifiedTranscript = modifiedTranscript.replace(/(colon\s*)+/g, ': ');
        modifiedTranscript = modifiedTranscript.replace(/(semicolon\s*)+/g, '; ');
        modifiedTranscript = modifiedTranscript.replace(/(question mark\s*)+/g, '? ');
        // modifiedTranscript = modifiedTranscript.replace(/(cubic centimeter\s*)+/g, 'cm3 ');
        modifiedTranscript = modifiedTranscript.replace(/(plus\s*)+/g, '+');
        modifiedTranscript = modifiedTranscript.replace(/(minus\s*)+/g, '-');
        modifiedTranscript = modifiedTranscript.replace(/(multiply\s*)+/g, '*');
        modifiedTranscript = modifiedTranscript.replace(/(divide\s*)+/g, '/');

        modifiedTranscript = modifiedTranscript.charAt(0).toUpperCase() + modifiedTranscript.slice(1);

        const newValue = saveReports + modifiedTranscript;
        setSaveReports(newValue);
        localStorage.setItem(`${username}_transcript`, newValue);
        resetTranscript();
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setErrMsg('');
    };

    const stopCommand = () => {
        SpeechRecognition.stopListening();
        resetTranscript();
    };

    const handleQuillChange = (content, delta, source, editor) => {
        const html = editor.getHTML();
        setSaveReports(html)
        localStorage.setItem(`${username}_transcript`, html);

        if (quillRef.current && quillRef.current.getEditor()) {
            const quillInstance = quillRef.current.getEditor();
            const scrollLength = quillInstance.scroll.domNode.scrollHeight;
            quillInstance.scroll.domNode.scrollTop = scrollLength;
        }

    };

    const handleTranscriptChange = (newTranscript) => {
        if (textAreaRef.current) {
            textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
        }
        return newTranscript;
    };


    const handleFileChange = (e) => {
        const fileName = e.target.value;
        setSelectedFile(fileName);
        setFileContentAppended(false);
        if (fileContent && !fileContentLoading && !fileContentError) {
            refetchFileContent();
        }
        setSearchTerm('');
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredFiles = files?.filter(file => file.template_name.toLowerCase().includes(searchTerm.toLowerCase()));

    const onTitleChanged = e => {
        const newTitle = e.target.value;
        setTitle(newTitle);
        setValidTitle(TITLE_REGEX.test(newTitle));
        if (!TITLE_REGEX.test(newTitle)) {
            setErrMsg('Should have 3 - 255 characters.');
            titleRef.current.focus();
        } else {
            setErrMsg('');
        }
    };

    const onAccessionChanged = e => {
        const newAccessionno = e.target.value;
        setAccessionno(newAccessionno);
        setValidAccessionno(ACCESSION_REGEX.test(newAccessionno));
        if (!ACCESSION_REGEX.test(newAccessionno)) {
            setErrMsg('Should have 4 - 30 characters and no space.');
            accessionRef.current.focus();
        } else {
            setErrMsg('');
        }
    };

    const onUserIdChanged = e => setUserId(e.target.value)
    const onAccessIdChanged = e => {
        const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
        setAccess_Ids(selectedOptions);
    }

    const canSave = [userId.length, validTitle, validAccessionno].every(Boolean) && !isLoading

    const onSaveReportClicked = async (e) => {
        e.preventDefault()
        if (canSave) {
            await addNewReport({ user: userId, title, accessionno: accessionno, dob, sex, originator: username, text: saveReports, access_ids: access_Ids, institute: selectedInstitute, priority, modality })
        }
    }

    const options = [<option key="default" value="">Select a consultant</option>];

    users.forEach(user => {
        if (user.roles[0] === "Consultant") {
            options.push(
                <option
                    key={user.id}
                    value={user.id}
                > {user.username}</option >
            );
        }
    });

    const options2 = users.map(user => {
        if (user.roles[0] === "Consultant") {
            return (
                <option
                    key={user.id}
                    value={user.username}
                > {user.username}</option >
            )
        } else {
            return null;
        }
    })

    const filteredInstitutes = institutes?.filter(institute => institute.institute_list.toLowerCase().includes(searchInstituteTerm.toLowerCase()));


    const handleInstituteChange = (e) => {
        const selectedValue = e.target.value;
        setSelectedInstitute(selectedValue);

        // Find the associated SOP for the selected institute
        const foundInstitute = filteredInstitutes.find(institute => institute.institute_list === selectedValue);

        if (foundInstitute) {
            setSelectedInstituteSOP(foundInstitute?.institute_sop || "No SOP available");
            setSelectedInstituteSOPManager(foundInstitute?.institute_sop_manager || "No SOP available");
        } else {
            setSelectedInstituteSOP("");
            setSelectedInstituteSOPManager("");
        }
        setSearchInstituteTerm('');
    };

    const formatSOP = (text) => {
        return text.split('//').map((paragraph, index) => (
            <p key={index} style={{ marginBottom: '1px' }}>
                {paragraph.split(/("[^"]+"|'[^']+')/).map((part, i) => {
                    if (part.startsWith('"') && part.endsWith('"')) {
                        return <strong key={i}>{part.replace(/"/g, '')}</strong>;
                    } else if (part.startsWith("'") && part.endsWith("'")) {
                        return <span key={i} style={{ textDecoration: 'underline' }}>{part.replace(/'/g, '')}</span>;
                    }
                    return part;
                })}
            </p>
        ));
    };

    const handleInstituteSearchChange = (e) => {
        setSearchInstituteTerm(e.target.value);
    };

    const content = (
        <>
            <Container style={{ marginTop: '6rem', marginBottom: '7rem' }}>
                <Row className="align-items-center">
                    <Col xs={12} md={4}>
                        <h2>New Report</h2>
                        <form onSubmit={onSaveReportClicked}>
                            <label style={{ fontWeight: 'bold', marginRight: '10px' }}>Title: </label>

                            <div>
                                <input
                                    type="text"
                                    autoComplete="off"
                                    placeholder='Report title'
                                    title='Report title'
                                    style={{ width: '100%', borderRadius: '6px', padding: '4px 8px', border: '2px solid #ccc', marginRight: '10px', marginTop: '10px' }}
                                    value={title}
                                    onChange={onTitleChanged}
                                    ref={titleRef}
                                    className={!validTitle ? 'error-input' : ''}
                                    required
                                />
                                {!validTitle && <span className="error-message">Minimum 3 characters.</span>}
                            </div>

                            <div>
                                <label style={{ fontWeight: 'bold', marginRight: '10px' }}>Accession: </label>
                                <input
                                    type="text"
                                    autoComplete="off"
                                    placeholder='Accession no'
                                    title='Accession no'
                                    style={{ width: '100%', borderRadius: '6px', padding: '4px 8px', border: '2px solid #ccc', marginRight: '10px', marginTop: '10px' }}
                                    value={accessionno}
                                    onChange={onAccessionChanged}
                                    ref={accessionRef}
                                    className={!validAccessionno ? 'error-input' : ''}
                                    required
                                />
                                {!validAccessionno && <span className="error-message">Minimum 4 - 30 characters and no space.</span>}
                            </div>

                            {(isAdmin || isManager) && (
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <div style={{ flex: 1, marginRight: '10px' }}>
                                        <label style={{ fontWeight: 'bold', marginRight: '10px', marginTop: '5px' }}>Date of Birth:</label>
                                        <input
                                            type="date"
                                            className="dob-input"
                                            title="Date of birth"
                                            value={dob}
                                            onChange={(e) => setDob(e.target.value)}
                                            required
                                            style={{ width: '100%' }}
                                        />
                                    </div>

                                    <div style={{ flex: 1 }}>
                                        <label style={{ fontWeight: 'bold', marginRight: '10px', marginTop: '5px' }}>Sex:</label>
                                        <select
                                            className="new-report-select"
                                            title="Select gender"
                                            value={sex}
                                            onChange={(e) => setSex(e.target.value)}
                                            required
                                            style={{ width: '100%' }}
                                        >
                                            <option value="">Select sex</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                </div>
                            )}
                            {(!isAdmin && !isManager) && (
                                <div style={{ marginRight: '10px' }}>
                                    <label style={{ fontWeight: 'bold', marginRight: '10px', marginTop: '5px' }}>Date of Birth:</label>
                                    <input
                                        type="date"
                                        className="dob-input"
                                        title="Date of birth"
                                        value={dob}
                                        onChange={(e) => setDob(e.target.value)}
                                        required
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            )}

                            {(!isAdmin && !isManager) && (
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }}>

                                    <div style={{ flex: 1, marginRight: '10px' }}>
                                        <label style={{ fontWeight: 'bold', marginRight: '10px' }}>Modality:</label>
                                        <select
                                            value={modality}
                                            onChange={(e) => setModality(e.target.value)}
                                            className="new-report-select"
                                            title="Report modality"
                                            required
                                            style={{ width: '100%', verticalAlign: 'middle' }}
                                        >
                                            <option value="">Select a modality</option>
                                            <option value="CR/DX">CR/DX</option>
                                            <option value="US">US</option>
                                            <option value="CT">CT</option>
                                            <option value="MR">MR</option>
                                            <option value="MG">MG</option>
                                        </select>
                                    </div>

                                    <div style={{ flex: 1 }}>
                                        <label style={{ fontWeight: 'bold', marginRight: '10px' }}>Sex:</label>
                                        <select
                                            className="new-report-select"
                                            title="Select gender"
                                            value={sex}
                                            onChange={(e) => setSex(e.target.value)}
                                            required
                                            style={{ width: '100%', verticalAlign: 'middle' }}
                                        >
                                            <option value="">Select sex</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                </div>
                            )}


                            {(isAdmin || isManager) && (
                                <>
                                    <div style={{ position: 'relative' }}>
                                        <label style={{ fontWeight: 'bold', marginRight: '10px', marginBottom: '10px' }}>Institutes: </label>

                                        <input
                                            type="text"
                                            title="Search institute"
                                            placeholder="Search for an institute"
                                            style={{ width: '100%', borderRadius: '6px', padding: '4px 8px', border: '2px solid #ccc', marginRight: '10px' }}
                                            value={searchInstituteTerm}
                                            onChange={handleInstituteSearchChange}
                                        />
                                        {(searchInstituteTerm && filteredInstitutes) && (
                                            <div className="autocomplete-dropdown">
                                                {filteredInstitutes && filteredInstitutes.map(institute => (
                                                    <div
                                                        key={institute.institute_list}
                                                        onClick={() => handleInstituteChange({ target: { value: institute.institute_list } })}
                                                        className="autocomplete-suggestion"
                                                    >
                                                        {institute.institute_list}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                    <select
                                        as="select"
                                        className="new-report-select"
                                        title="Select institute"
                                        required
                                        value={selectedInstitute}
                                        onChange={handleInstituteChange}
                                        style={{ marginTop: '10px' }}
                                    >
                                        <option value="">Select an institute</option>
                                        {filteredInstitutes && filteredInstitutes.map(institute => (
                                            <option key={institute.institute_list} value={institute.institute_list}>{institute.institute_list}</option>
                                        ))}
                                    </select>
                                    {(sopaccess || sopaccessmanager || isAdmin) && selectedInstitute && (
                                        <div style={{
                                            marginTop: '10px',
                                            padding: '10px',
                                            border: '2px solid #ccc',
                                            borderRadius: '6px',
                                            backgroundColor: '#ffffff',
                                            color: '#000',
                                            height: '100px', // Increased height for better readability
                                            overflowY: 'auto'
                                        }}>
                                            {sopaccess && !sopaccessmanager && !isAdmin && (
                                                <>
                                                    <strong>Institute SOP [Consultant]:</strong>
                                                    <p style={{ whiteSpace: 'pre-line', marginTop: '5px' }}>
                                                        {formatSOP(selectedInstituteSOP)}
                                                    </p>
                                                </>
                                            )}

                                            {!sopaccess && sopaccessmanager && !isAdmin && (
                                                <>
                                                    <strong>Institute SOP [Manager]:</strong>
                                                    <p style={{ whiteSpace: 'pre-line', marginTop: '5px' }}>
                                                        {formatSOP(selectedInstituteSOPManager)}
                                                    </p>
                                                </>
                                            )}

                                            {(sopaccess && sopaccessmanager) || isAdmin ? (
                                                <>
                                                    <strong>Institute SOP [Manager]:</strong>
                                                    <p style={{ whiteSpace: 'pre-line', marginTop: '5px' }}>
                                                        {formatSOP(selectedInstituteSOPManager)}
                                                    </p>

                                                    <strong>Institute SOP [Consultant]:</strong>
                                                    <p style={{ whiteSpace: 'pre-line', marginTop: '5px' }}>
                                                        {formatSOP(selectedInstituteSOP)}
                                                    </p>
                                                </>
                                            ) : null}
                                        </div>
                                    )}
                                </>
                            )}

                            {(isAdmin || isManager) && (
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <div style={{ flex: 2, marginRight: '10px' }}>
                                        {(isAdmin || isManager) && (
                                            <div>
                                                <div style={{ marginBottom: '5px' }}>
                                                    <label style={{ fontWeight: 'bold', marginRight: '10px', marginTop: '5px' }}>Priority: </label>
                                                </div>
                                                <div>
                                                    <select
                                                        value={priority}
                                                        onChange={(e) => setPriority(e.target.value)}
                                                        as="select"
                                                        title="Report priority"
                                                        className="new-report-select"
                                                        style={{ width: '100%', marginRight: '10px', marginTop: '10px' }}
                                                    >
                                                        <option value="Routine">Routine</option>
                                                        <option value="STAT">STAT</option>
                                                        <option value="ASAP">ASAP</option>
                                                    </select>
                                                </div>
                                            </div>
                                        )}
                                    </div>


                                    <div style={{ flex: 2 }}>
                                        <div>
                                            <div style={{ marginBottom: '5px' }}>
                                                <label style={{ fontWeight: 'bold', marginRight: '10px', marginTop: '5px' }}>Modality: </label>
                                            </div>
                                            <div>
                                                <select
                                                    value={modality}
                                                    onChange={(e) => setModality(e.target.value)}
                                                    as="select"
                                                    className="new-report-select"
                                                    title="Report modality"
                                                    required
                                                    style={{ width: '100%', marginRight: '10px', marginTop: '10px' }}
                                                >
                                                    <option value="">Select a modality</option>
                                                    <option value="CR/DX">CR/DX</option>
                                                    <option value="US">US</option>
                                                    <option value="CT">CT</option>
                                                    <option value="MR">MR</option>
                                                    <option value="MG">MG</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}


                            <label style={{ fontWeight: 'bold', marginRight: '10px' }}>Templates: </label>
                            <input
                                type="text"
                                placeholder="Search for a template"
                                title="Search template"
                                style={{ width: '100%', borderRadius: '6px', padding: '4px 8px', border: '2px solid #ccc', marginRight: '10px', marginTop: '10px' }}
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                            <select
                                as="select"
                                className="new-report-select"
                                title="Select template"
                                value={selectedFile || ''}
                                onChange={handleFileChange}
                            >
                                <option value="">Select a template</option>
                                {filteredFiles?.map(file => (
                                    <option key={file.template_name} value={file.template_name}>
                                        {file.template_name}
                                    </option>
                                ))}
                            </select>
                            {searchTerm && filteredFiles?.length > 0 && (
                                <div className="autocomplete-dropdown">
                                    {filteredFiles?.map(file => {
                                        const fileNameWithoutExtension = file.template_name; // Access the template_name directly
                                        return (
                                            <div
                                                key={file.template_name}
                                                onClick={() => handleFileChange({ target: { value: fileNameWithoutExtension } })} // Use fileNameWithoutExtension here
                                                className="autocomplete-suggestion"
                                            >
                                                {fileNameWithoutExtension}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}

                            {(isAdmin || isManager) && (
                                <div>
                                    <div style={{ marginBottom: '5px' }}>
                                        <label style={{ fontWeight: 'bold', marginRight: '10px', marginTop: '5px' }}>Assign: </label>
                                    </div>
                                    <div>
                                        <select
                                            as="select"
                                            className="new-report-select"
                                            title="Assign consultent"
                                            required
                                            value={userId}
                                            onChange={onUserIdChanged}
                                        >
                                            {options}
                                        </select>
                                    </div>
                                </div>
                            )}

                            {(isAdmin || isManager) && (
                                <div style={{ overflow: 'hidden', scrollbarWidth: 'none' }}>
                                    <div style={{ marginBottom: '5px' }}>
                                        <label style={{ fontWeight: 'bold', marginRight: '10px', marginTop: '5px' }}>Access: </label>
                                    </div>
                                    <select
                                        as="select"
                                        value={access_Ids}
                                        onChange={onAccessIdChanged}
                                        multiple
                                        className="new-report-select"
                                        title="Give access"
                                        style={{
                                            overflowX: 'hidden',
                                            overflowY: 'scroll',
                                            scrollbarWidth: 'thin',
                                            border: '2px solid #ccc',
                                        }}
                                    >
                                        {options2}
                                    </select>
                                </div>
                            )}

                            <button className="new-report-button" title="Save report" type="submit" disabled={isLoading}>
                                <FontAwesomeIcon icon={faSave} /> {isLoading ? 'Saving...' : `Save`}
                            </button>
                        </form>
                    </Col>

                    <Col xs={12} md={8}>
                        {/* <div style={{ marginBottom: '5px' }}>
                            <label style={{ fontWeight: 'bold', marginRight: '10px', marginLeft: '5px' }}>Report: </label>
                        </div> */}
                        <ReactQuill theme="snow" value={saveReports}
                            onChange={handleQuillChange}
                            modules={modules}
                            ref={quillRef}
                            className='react-quill'
                            title="Report editor"
                            style={{ background: 'white', borderRadius: '5px', color: 'black' }}
                        />

                        <div style={{ marginTop: '5px', marginBottom: '10px' }}>

                            <button onClick={listening ? stopListening : startListening} title="Microphone" className={`mic-btn ${listening ? 'listening' : ''}`}>
                                {listening ?
                                    <FontAwesomeIcon icon={faMicrophone} /> :
                                    <FontAwesomeIcon icon={faMicrophoneSlash} />
                                }
                            </button>

                            <label className="auto-start-button">
                                <input type="checkbox" title="Auto start mic" onClick={() => setIsAutoListening(!isAutoListening)} />
                                <span className="slider round"></span>
                            </label>

                            <textarea
                                ref={textAreaRef}
                                value={handleTranscriptChange(transcript)}
                                readOnly
                                title="Transcript text"
                                style={{
                                    width: '80%',
                                    marginLeft: '10px',
                                    resize: 'none',
                                    textDecoration: 'none',
                                    height: '30px',
                                    borderRadius: '5px',
                                    verticalAlign: 'middle'
                                }}
                            />
                        </div>

                    </Col>
                </Row>
            </Container>
            <Modal show={showModal} onClose={handleCloseModal}>
                <p>{errMsg}</p>
            </Modal>
        </>
    )

    return content
}

export default NewReportForm
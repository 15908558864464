import { useSelector } from 'react-redux'
import { selectCurrentToken } from "../features/auth/authSlice"
import { jwtDecode } from 'jwt-decode'

const useAuth = () => {
    const token = useSelector(selectCurrentToken)
    let isManager = false
    let isAdmin = false
    let isQA = false
    let status = "Consultant"

    if (token) {
        const decoded = jwtDecode(token)
        const { username, roles, fullname, annotationviewer, sopaccess, sopaccessmanager } = decoded.UserInfo

        isManager = roles.includes('Manager')
        isAdmin = roles.includes('Admin')
        isQA = roles.includes('QA')

        if (isManager) status = "Manager"
        if (isAdmin) status = "Admin"
        if (isQA) status = "QA"

        return { username, roles, fullname, annotationviewer, status, isManager, isAdmin, isQA, sopaccess, sopaccessmanager }
    }

    return { username: '', fullname: '', roles: [], annotationviewer: false, isManager, isAdmin, isQA, status, sopaccess: false, sopaccessmanager: false }
}
export default useAuth
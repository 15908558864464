import React, { useState, useEffect, useRef } from "react";
import { useUpdateReportMutation, useDeleteReportMutation, useUpdateNotificationMutation } from "./reportsApiSlice"
import { useLocation, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComment, faMicrophone, faMicrophoneSlash, faSave, faTrashCan, faCheck, faPen, faCircleRight } from "@fortawesome/free-solid-svg-icons"
import useAuth from "../../hooks/useAuth"
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useGetTemplateContentQuery, useGetTemplatesQuery } from "../temp/tempApiSlice";
import ChatBox from "../message/ChatBox";
import Modal from 'react-modal';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { useGetInstitutesQuery } from "../insti/instituteApiSlice";
import { Container, Row, Col } from 'react-bootstrap';
import Modal1 from '../../components/Modal';


const modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ font: [] }],
        [{ size: [] }],
        [{ align: '' }, { align: 'center' }, { align: 'right' }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
        ],
        ["link", "image", "video"],
    ],
};

const TITLE_REGEX = /^[\w\s\S]{3,255}$/

const EditReportForm = ({ report, users }) => {

    const location = useLocation();
    const { isManager, isAdmin, isQA, username, sopaccess, sopaccessmanager } = useAuth()

    const commands = [
        {
            command: 'delete',
            callback: () => {
                if (window.confirm('Are you sure you want to delete all report data?')) {
                    setSaveReports([]);
                    SpeechRecognition.stopListening();
                    resetTranscript();
                    localStorage.clear();
                    setIsAutoListening(false);
                }
                else {
                    SpeechRecognition.stopListening();
                    resetTranscript();
                }
            }
        },
        {
            command: 'next line',
            callback: () => {
                stopListening();
                if (isAutoListening) {
                    setTimeout(startListening, 500);
                }
            }
        },
        {
            command: 'title *',
            callback: (title) => {
                let upperCaseText = title.toUpperCase();
                const newValue = saveReports + `<h1 class="ql-align-center"><strong><u>${upperCaseText}</u></strong></h1><br/>`;
                setSaveReports(newValue);
                localStorage.setItem(`${report._id}_transcript`, newValue);
                stopCommand();
                if (isAutoListening) {
                    setTimeout(startListening, 500);
                }
            }
        },
        {
            command: 'history',
            callback: () => {
                stopListening();
                const newValue = saveReports + '<br/><h2><strong>Clinical History: </strong></h2>';
                setSaveReports(newValue);
                localStorage.setItem(`${report._id}_transcript`, newValue);
                if (isAutoListening) {
                    setTimeout(startListening, 500);
                }
            }
        },
        {
            command: 'technique',
            callback: () => {
                stopListening();
                const newValue = saveReports + '<br/><h2><strong>Technique: </strong></h2>';
                setSaveReports(newValue);
                localStorage.setItem(`${report._id}_transcript`, newValue);
                if (isAutoListening) {
                    setTimeout(startListening, 500);
                }
            }
        },
        {
            command: 'findings',
            callback: () => {
                stopListening();
                const newValue = saveReports + '<br/><h2><strong>Findings: </strong></h2>';
                setSaveReports(newValue);
                localStorage.setItem(`${report._id}_transcript`, newValue);
                if (isAutoListening) {
                    setTimeout(startListening, 500);
                }
            }
        },
        {
            command: 'impression',
            callback: () => {
                stopListening();
                const newValue = saveReports + '<br/><h2><strong>Impression: </strong></h2>';
                setSaveReports(newValue);
                localStorage.setItem(`${report._id}_transcript`, newValue);
                if (isAutoListening) {
                    setTimeout(startListening, 500);
                }
            }
        },
        {
            command: 'advice',
            callback: () => {
                stopListening();
                const newValue = saveReports + '<br/><h2><strong>Advice: </strong></h2>';
                setSaveReports(newValue);
                localStorage.setItem(`${report._id}_transcript`, newValue);
                if (isAutoListening) {
                    setTimeout(startListening, 500);
                }
            }
        },
        {
            command: 'underline *',
            callback: (underline) => {
                const newValue = saveReports + `<u>${underline}</u>. `;
                setSaveReports(newValue);
                localStorage.setItem(`${report._id}_transcript`, newValue);
                stopCommand();
                if (isAutoListening) {
                    setTimeout(startListening, 500);
                }
            }
        },
        {
            command: 'bold face *',
            callback: (point) => {
                const newValue = saveReports + `<strong>${point}</strong>. `;
                setSaveReports(newValue);
                localStorage.setItem(`${report._id}_transcript`, newValue);
                stopCommand();
                if (isAutoListening) {
                    setTimeout(startListening, 500);
                }
            }
        },
    ];

    const [title, setTitle] = useState(report.title);
    const [userId, setUserId] = useState(report.user_id);
    const [accessionno, setAccessionno] = useState(report.accessionno);
    const [validTitle, setValidTitle] = useState(false)
    const [completed, setCompleted] = useState(report.completed)
    const [access_Ids, setAccess_Ids] = useState(report.access_ids)
    const [saveReports, setSaveReports] = useState(localStorage.getItem(`${report._id}_transcript`) || []);
    const [selectedFile, setSelectedFile] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [isAutoListening, setIsAutoListening] = useState(false);
    const textAreaRef = useRef(null);
    const quillRef = useRef(null);
    const titleRef = useRef(null);
    const [fileContentAppended, setFileContentAppended] = useState(false);
    const [currentFileContent, setCurrentFileContent] = useState('');
    const [selectedInstitute, setSelectedInstitute] = useState(report.institute);
    const [searchTermInstitute, setSearchTermInstitute] = useState('');
    const [priority, setPriority] = useState(report.priority);
    const [modality, setModality] = useState(report.modality);
    const [dob, setDob] = useState(report.dob);
    const [sex, setSex] = useState(report.sex);
    const [errMsg, setErrMsg] = useState('');
    const [showModal, setShowModal] = useState(false);

    const [checks, setChecks] = useState({
        StudyTitle: false,
        ModalityInstitute: false,
        GrammarSpelling: false,
        ReportFormat: false,
        PACSUpload: false,
    });

    const allChecked = Object.values(checks).every(Boolean);
    const someChecked = Object.values(checks).some(Boolean);

    const handleChange = (e) => {
        const { name, checked } = e.target;
        setChecks((prev) => ({
            ...prev,
            [name]: checked,
        }));
    };

    const handleSelectAll = () => {
        const newCheckedState = !allChecked;
        setChecks((prev) =>
            Object.keys(prev).reduce((acc, key) => {
                acc[key] = newCheckedState;
                return acc;
            }, {})
        );
    };

    useEffect(() => {
        setValidTitle(TITLE_REGEX.test(title))
    }, [title])

    const created = new Date(report.createdat).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })
    const updated = new Date().toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' });

    const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition({ commands });

    const [isChatOpen, setIsChatOpen] = useState(false);

    const [updateReport, {
        isLoading,
        isSuccess,
        // eslint-disable-next-line
        isError,
        error
    }] = useUpdateReportMutation()

    const [updateNotification] = useUpdateNotificationMutation();

    const [deleteReport, {
        isSuccess: isDelSuccess,
        // eslint-disable-next-line
        isError: isDelError,
        // eslint-disable-next-line
        error: delerror
    }] = useDeleteReportMutation()

    const {
        data: files,
        // eslint-disable-next-line
        error: filesError,
        // eslint-disable-next-line
        isLoading: filesLoading,
    } = useGetTemplatesQuery();

    const {
        data: fileContent,
        error: fileContentError,
        isLoading: fileContentLoading,
        refetch: refetchFileContent,
    } = useGetTemplateContentQuery(selectedFile, { skip: !selectedFile });

    useEffect(() => {

        if (!(localStorage.getItem(`${report._id}_transcript`))) {
            localStorage.setItem(`${report._id}_transcript`, report.text);
            setSaveReports(localStorage.getItem(`${report._id}_transcript`));
        }

    }, [report]);

    useEffect(() => {
        if (fileContent && !fileContentLoading && !fileContentError) {
            setCurrentFileContent(fileContent.data);
        }
    }, [fileContent, fileContentLoading, fileContentError]);

    useEffect(() => {
        if (currentFileContent && !fileContentAppended) {
            const newValue = saveReports + currentFileContent;
            setSaveReports(newValue);
            localStorage.setItem(`${report._id}_transcript`, newValue);
            setFileContentAppended(true);
            setCurrentFileContent('')
        }
    }, [currentFileContent, fileContentAppended, saveReports, report._id]);

    useEffect(() => {
        const updateReportIfRequired = async () => {
            if (report.username === username && report.notification === "2") {
                await updateNotification({
                    id: report._id,
                    notification: 0
                });
            } else if (report.username !== username && report.notification === "1") {
                await updateNotification({
                    id: report._id,
                    notification: 0
                });
            }
        };

        updateReportIfRequired();
    }, [report._id, username, report.username, report.notification, updateNotification]);

    useEffect(() => {
        const handleBeforeUnload = () => {
            localStorage.removeItem(`${report._id}_transcript`);
        };
        window.addEventListener('beforeunload', handleBeforeUnload);

        return async () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [report._id]);

    const navigate = useNavigate()

    useEffect(() => {

        if (isSuccess || isDelSuccess) {
            setTitle('')
            setAccessionno('')
            setSaveReports('')
            localStorage.removeItem(`${report._id}_transcript`)
            setAccess_Ids('')
            navigate(-1)
        }

        if (report.institute !== "N/A") {
            setSelectedInstitute(report.institute);
        }

    }, [isSuccess, isDelSuccess, report._id, report.text, navigate, report.institute])

    useEffect(() => {
        return () => {
            localStorage.removeItem(`${report._id}_transcript`);
        };
    }, [location, report._id]);

    const {
        data: institutes,
        // eslint-disable-next-line
        error: institutesError,
        // eslint-disable-next-line
        isLoading: institutesLoading,
    } = useGetInstitutesQuery();

    useEffect(() => {
        if (fileContentError) {
            setErrMsg('Failed to fetch file content.');
            setShowModal(true);
        }
    }, [fileContentError]);

    useEffect(() => {
        if (error) {
            setErrMsg(error.data?.message || 'Failed to save report.');
            setShowModal(true);
        }
    }, [error]);

    if (!browserSupportsSpeechRecognition) {
        return <span>Browser doesn't support speech recognition.</span>;
    }

    const startListening = () => {
        resetTranscript();
        SpeechRecognition.startListening({ continuous: true });
    };

    const stopListening = () => {
        SpeechRecognition.stopListening();

        let modifiedTranscript = transcript.replace(/( stop| full stop| period\s*)+/g, '. ');
        modifiedTranscript = modifiedTranscript.replace(/(next\s*line\s*)+/g, '');
        modifiedTranscript = modifiedTranscript.replace(/(comma\s*)+/g, ', ');
        modifiedTranscript = modifiedTranscript.replace(/(hyphen\s*)+/g, '-');
        modifiedTranscript = modifiedTranscript.replace(/( underscore\s*)+/g, '_');
        modifiedTranscript = modifiedTranscript.replace(/(colon\s*)+/g, ': ');
        modifiedTranscript = modifiedTranscript.replace(/(semicolon\s*)+/g, '; ');
        modifiedTranscript = modifiedTranscript.replace(/(question mark\s*)+/g, '? ');
        // modifiedTranscript = modifiedTranscript.replace(/(cubic centimeter\s*)+/g, 'cm3 ');
        modifiedTranscript = modifiedTranscript.replace(/(plus\s*)+/g, '+');
        modifiedTranscript = modifiedTranscript.replace(/(minus\s*)+/g, '-');
        modifiedTranscript = modifiedTranscript.replace(/(multiply\s*)+/g, '*');
        modifiedTranscript = modifiedTranscript.replace(/(divide\s*)+/g, '/');

        modifiedTranscript = modifiedTranscript.charAt(0).toUpperCase() + modifiedTranscript.slice(1);

        const newValue = saveReports + modifiedTranscript;
        setSaveReports(newValue);
        localStorage.setItem(`${report._id}_transcript`, newValue);
        resetTranscript();
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setErrMsg('');
    };

    const stopCommand = () => {
        SpeechRecognition.stopListening();
        resetTranscript();
    };

    const handleTranscriptChange = (newTranscript) => {
        if (textAreaRef.current) {
            textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
        }
        return newTranscript;
    };

    const openChat = (e) => {
        e.stopPropagation();
        setIsChatOpen(true);
    };

    const closeChat = async () => {
        setIsChatOpen(false);
        if (report.username !== username && report.notification !== "2") {
            await updateNotification({
                id: report._id,
                notification: 2
            });
        } else if (report.username === username && report.notification !== "1") {
            await updateNotification({
                id: report._id,
                notification: 1
            });
        }
    };

    const handleQuillChange = (content, delta, source, editor) => {
        const html = editor.getHTML();
        setSaveReports(html)
        localStorage.setItem(`${report._id}_transcript`, html);

        if (quillRef.current && quillRef.current.getEditor()) {
            const quillInstance = quillRef.current.getEditor();
            const scrollLength = quillInstance.scroll.domNode.scrollHeight;
            quillInstance.scroll.domNode.scrollTop = scrollLength;
        }

    };

    const handleFileChange = (e) => {
        const fileName = e.target.value;
        setSelectedFile(fileName);
        setFileContentAppended(false);
        if (fileContent && !fileContentLoading && !fileContentError) {
            refetchFileContent();
        }
        setSearchTerm('');
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredFiles = files?.filter(file => file.template_name.toLowerCase().includes(searchTerm.toLowerCase()));

    const onTitleChanged = e => {
        const newTitle = e.target.value;
        setTitle(newTitle);
        setValidTitle(TITLE_REGEX.test(newTitle));
        if (!TITLE_REGEX.test(newTitle)) {
            setErrMsg('Title must be between 3 - 255 characters.');
            titleRef.current.focus();
        } else {
            setErrMsg('');
        }
    };
    const onCompletedChanged = e => setCompleted(prev => prev !== "true" ? "true" : "false");

    const onAccessIdChanged = e => {
        const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
        setAccess_Ids(selectedOptions);
    }

    let updatedCompleted = completed;
    if (!isAdmin && !isManager && completed === "false") {
        updatedCompleted = "Read";
    }

    const canSave = [validTitle].every(Boolean) && !isLoading

    const saveReport = async (completionStatus) => {
        if (canSave) {
            let notificationValue = 2;

            if (report.username === username) {
                notificationValue = report.notification === "1" ? 1 : 0;
            }

            await updateReport({
                id: report._id,
                user: userId,
                title,
                accessionno,
                dob,
                sex,
                originator: report.originator,
                text: saveReports,
                completed: completionStatus,
                access_ids: access_Ids,
                priority,
                modality,
                institute: selectedInstitute,
                notification: notificationValue,
                currentUser: username
            });
        }
    };

    const onSaveReportClicked = async (e) => {
        e.preventDefault();
        await saveReport(updatedCompleted);
    };

    const onApproveReportClicked = async (e) => {
        e.preventDefault();
        await saveReport("Approved");
    };

    const onAddendumReportClicked = async (e) => {
        e.preventDefault();
        await saveReport("Addendum");
    };

    const onFinalReportClicked = async (e) => {
        e.preventDefault();
        await saveReport("Final");
    };

    const onDeleteReportClicked = async (e) => {
        e.preventDefault();
        const confirmDelete = window.confirm("Are you sure you want to delete this report?");
        if (confirmDelete) {
            await deleteReport({ id: report.accessionno, currentUser: username })
        }
    }

    const handleInstituteChange = (e) => {
        const instituteName = e.target.value;
        setSelectedInstitute(instituteName);
        setSearchTermInstitute('');
    };

    const handleSearchInstituteChange = (e) => {
        setSearchTermInstitute(e.target.value);
    };

    const filteredInstitutes = institutes?.filter(institute => institute.institute_list.toLowerCase().includes(searchTermInstitute.toLowerCase()));

    const options = users.map(user => {
        if (user.roles[0] === "Consultant") {
            return (
                <option
                    key={user.id}
                    value={user.username}
                > {user.username}</option >
            )
        } else {
            return null;
        }
    })

    const onUserIdChanged = e => setUserId(e.target.value)

    const options2 = [<option key="default" value="">Select a consultant</option>];

    users.forEach(user => {
        if (user.roles[0] === "Consultant") {
            options2.push(
                <option
                    key={user.id}
                    value={user.id}
                > {user.username}</option >
            );
        }
    });

    const cellStyle = {
        padding: '6px',
        border: '2px solid #ccc', // Subtle border
        textAlign: 'center',
        fontSize: '14px',
    };

    let deleteButton = null
    if (isManager || isAdmin) {
        deleteButton = (
            <button
                className="delete-btn"
                title="Delete report"
                onClick={onDeleteReportClicked}
            ><FontAwesomeIcon icon={faTrashCan} />{''}
            </button>
        )
    }

    const handleDateChange = (e) => {
        const selectedDate = e.target.value;
        const extractedDate = selectedDate.split('T')[0];
        setDob(extractedDate);
    };

    const selectedInstituteData = institutes?.find(institute => institute.institute_list === selectedInstitute);
    const selectedInstituteSOP = selectedInstituteData?.institute_sop || "No SOP available";
    const selectedInstituteSOPManager = selectedInstituteData?.institute_sop_manager || "No SOP available";

    const formatSOP = (text, dataSop) => {
        const paragraphs = text.split('//');

        return (
            <div>
                {/* First SOP item remains inline with "SOP:" */}
                <strong style={{ color: '#055449cd' }}>{dataSop}</strong>
                {paragraphs.slice(0, 1).map((paragraph, index) => (
                    <span key={index} style={{ marginRight: '5px' }}>
                        {formatText(paragraph)}
                    </span>
                ))}

                {/* Remaining SOP items appear in new paragraphs */}
                {paragraphs.slice(1).map((paragraph, index) => (
                    <p key={index} style={{ marginBottom: '1px', marginTop: '1px', marginLeft: '5px', marginRight: '5px' }}>
                        {formatText(paragraph)}
                    </p>
                ))}
            </div>
        );
    };

    // Helper function to format bold and underlined text
    const formatText = (paragraph) => {
        return paragraph.split(/("[^"]+"|'[^']+')/).map((part, i) => {
            if (part.startsWith('"') && part.endsWith('"')) {
                return <strong key={i}>{part.replace(/"/g, '')}</strong>;
            } else if (part.startsWith("'") && part.endsWith("'")) {
                return <span key={i} style={{ textDecoration: 'underline' }}>{part.replace(/'/g, '')}</span>;
            }
            return part;
        });
    };


    const content = (
        <>
            <Container style={{ marginTop: '6rem', marginBottom: '7rem' }}>
                <Row className="align-items-center">
                    <Col xs={12} md={4}>
                        <h2>Edit Report #{report.ticket}</h2>
                        <form onSubmit={onSaveReportClicked}>
                            <label style={{ fontWeight: 'bold', marginRight: '10px' }}>Title: </label>

                            <input
                                id="report-title"
                                name="title"
                                title='Report title'
                                type="text"
                                placeholder='Report title'
                                style={{ width: '100%', borderRadius: '6px', padding: '4px 8px', border: '2px solid #ccc', marginRight: '10px', marginTop: '10px' }}
                                autoComplete="off"
                                value={title}
                                onChange={onTitleChanged}
                                readOnly={!isAdmin && !isManager}
                                ref={titleRef}
                                className={!validTitle ? 'error-input' : ''}
                            />
                            {!validTitle && <span className="error-message">Title must be between 3 - 255 characters.</span>}

                            <div style={{ marginTop: '10px' }}>
                            </div>

                            {(isAdmin || isManager) && (
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <div style={{ flex: 1, marginRight: '10px' }}>
                                        <label style={{ fontWeight: 'bold', marginRight: '10px' }}>Date of Birth:</label>
                                        <input
                                            type="date"
                                            className="dob-input"
                                            title="Date of birth"
                                            value={dob ? dob : ""}
                                            onChange={handleDateChange}
                                            required
                                            style={{ width: '100%' }} // Ensure the input takes full width of the container
                                        />
                                    </div>

                                    <div style={{ flex: 1 }}>
                                        <label style={{ fontWeight: 'bold', marginRight: '10px' }}>Sex:</label>
                                        <select
                                            className="new-report-select"
                                            title="Select gender"
                                            value={sex}
                                            onChange={(e) => setSex(e.target.value)}
                                            required
                                            style={{ width: '100%' }} // Ensure the select takes full width of the container
                                        >
                                            <option value="">Select sex</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                </div>
                            )}

                            {(!isAdmin && !isManager) && (
                                <div>
                                    <label style={{ fontWeight: 'bold', marginRight: '10px' }}>Date of Birth:</label>
                                    <input
                                        type="date"
                                        className="dob-input"
                                        title="Date of birth"
                                        value={dob ? dob : ""}
                                        onChange={handleDateChange}
                                        required
                                        style={{ width: '100%' }} // Ensure the input takes full width of the container
                                    />
                                </div>
                            )}

                            {(!isAdmin && !isManager) && (
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }}>
                                    <div style={{ flex: 4, marginRight: '10px' }}>
                                        <label style={{ fontWeight: 'bold', marginRight: '10px' }}>Modality:</label>
                                        <select
                                            value={modality}
                                            onChange={(e) => setModality(e.target.value)}
                                            className="new-report-select"
                                            title="Report modality"
                                            required
                                            style={{ width: '100%' }}
                                        >
                                            <option value="">Select a modality</option>
                                            <option value="CR/DX">CR/DX</option>
                                            <option value="US">US</option>
                                            <option value="CT">CT</option>
                                            <option value="MR">MR</option>
                                            <option value="MG">MG</option>
                                        </select>
                                    </div>

                                    <div style={{ flex: 4 }}>
                                        <label style={{ fontWeight: 'bold', marginRight: '10px' }}>Sex:</label>
                                        <select
                                            className="new-report-select"
                                            title="Select gender"
                                            value={sex}
                                            onChange={(e) => setSex(e.target.value)}
                                            required
                                            style={{ width: '100%' }} // Ensure the select takes full width of the container
                                        >
                                            <option value="">Select sex</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                </div>

                            )}

                            {(isAdmin || isManager) && (
                                <>
                                    <label style={{ fontWeight: 'bold', marginRight: '10px' }}>Institutes: </label>
                                    <div style={{ position: 'relative', marginTop: '10px' }}>
                                        <input
                                            type="text"
                                            placeholder="Search for an institute"
                                            title="Search institute"
                                            style={{ width: '100%', borderRadius: '6px', padding: '4px 8px', border: '2px solid #ccc', marginRight: '10px' }}
                                            value={searchTermInstitute}
                                            onChange={handleSearchInstituteChange}
                                        />
                                        {(searchTermInstitute && filteredInstitutes) && (
                                            <div className="autocomplete-dropdown">
                                                {filteredInstitutes && filteredInstitutes.map(institute => (
                                                    <div
                                                        key={institute.institute_list}
                                                        onClick={() => handleInstituteChange({ target: { value: institute.institute_list } })}
                                                        className="autocomplete-suggestion"
                                                    >
                                                        {institute.institute_list}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                    <select
                                        as="select"
                                        id="institute"
                                        name="institute"
                                        className="new-report-select"
                                        title="Select institute"
                                        required
                                        value={selectedInstitute}
                                        onChange={handleInstituteChange}
                                        style={{ marginTop: '10px' }}
                                    >
                                        <option value="">Select an institute</option>
                                        {filteredInstitutes && filteredInstitutes.map(institute => (
                                            <option key={institute.institute_list} value={institute.institute_list}>{institute.institute_list}</option>
                                        ))}
                                    </select>
                                </>
                            )}

                            {(isAdmin || isManager) && (
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <div style={{ flex: 2, marginRight: '10px' }}>


                                        <div>
                                            <div style={{ marginBottom: '5px' }}>
                                                <label style={{ fontWeight: 'bold', marginRight: '10px', marginTop: '5px' }}>Priority: </label>
                                            </div>
                                            <div>
                                                <select
                                                    value={priority}
                                                    onChange={(e) => setPriority(e.target.value)}
                                                    as="select"
                                                    title="Report priority"
                                                    className="new-report-select"
                                                    style={{ width: '100%', marginRight: '10px', marginTop: '10px' }}
                                                >
                                                    <option value="Routine">Routine</option>
                                                    <option value="STAT">STAT</option>
                                                    <option value="ASAP">ASAP</option>
                                                </select>
                                            </div>
                                        </div>

                                    </div>

                                    <div style={{ flex: 2 }}>
                                        <div>
                                            <div style={{ marginBottom: '5px' }}>
                                                <label style={{ fontWeight: 'bold', marginRight: '10px', marginTop: '5px' }}>Modality: </label>
                                            </div>
                                            <div>
                                                <select
                                                    value={modality}
                                                    onChange={(e) => setModality(e.target.value)}
                                                    as="select"
                                                    className="new-report-select"
                                                    title="Report modality"
                                                    required
                                                    style={{ width: '100%', marginRight: '10px', marginTop: '10px' }}
                                                >
                                                    <option value="">Select a modality</option>
                                                    <option value="CR/DX">CR/DX</option>
                                                    <option value="US">US</option>
                                                    <option value="CT">CT</option>
                                                    <option value="MR">MR</option>
                                                    <option value="MG">MG</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <label style={{ fontWeight: 'bold', marginRight: '10px' }}>Templates: </label>
                            <input
                                type="text"
                                placeholder="Search for a template"
                                title="Search template"
                                style={{ width: '100%', borderRadius: '6px', padding: '4px 8px', border: '2px solid #ccc', marginRight: '10px', marginTop: '10px' }}
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                            <select
                                id="file"
                                name="file"
                                className="new-report-select"
                                title="Select template"
                                value={selectedFile || ''}
                                onChange={handleFileChange}
                            >
                                <option value="">Select a template</option>
                                {filteredFiles?.map(file => (
                                    <option key={file.template_name} value={file.template_name}>
                                        {file.template_name}
                                    </option>
                                ))}
                            </select>
                            {searchTerm && filteredFiles?.length > 0 && (
                                <div className="autocomplete-dropdown">
                                    {filteredFiles?.map(file => {
                                        const fileNameWithoutExtension = file.template_name; // Access the template_name directly
                                        return (
                                            <div
                                                key={file.template_name}
                                                onClick={() => handleFileChange({ target: { value: fileNameWithoutExtension } })} // Use fileNameWithoutExtension here
                                                className="autocomplete-suggestion"
                                            >
                                                {fileNameWithoutExtension}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}

                            <table
                                style={{
                                    width: '100%',
                                    borderCollapse: 'collapse',
                                    // backgroundColor: '#333', // Dark background for contrast
                                    color: 'gray', // Light text for readability
                                    borderRadius: '8px', // Rounded corners
                                    overflow: 'hidden', // Ensures border-radius applies properly
                                    marginTop: '10px'
                                }}
                            >
                                {(!isAdmin && !isManager) && (
                                    <tbody>
                                        <tr>
                                            <td style={cellStyle}><strong>Assigned:</strong> {report.username}</td>
                                            <td style={cellStyle}><strong>Priority:</strong> {report.priority}</td>
                                            <td style={cellStyle}><strong>Institute:</strong> {report.institute}</td>
                                        </tr>
                                    </tbody>
                                )}
                            </table>

                            {(isAdmin || isManager) && (
                                <div>
                                    <div style={{ marginBottom: '5px' }}>
                                        <label style={{ fontWeight: 'bold', marginRight: '10px', marginTop: '5px' }}>Assigned: </label>
                                    </div>
                                    <div>
                                        <select
                                            as="select"
                                            className="new-report-select"
                                            title="Assign consultent"
                                            required
                                            value={userId}
                                            onChange={onUserIdChanged}
                                        >
                                            {options2}
                                        </select>
                                    </div>
                                </div>
                            )}

                            {(isAdmin || isManager) && (
                                <div style={{ overflow: 'hidden', scrollbarWidth: 'none' }}>
                                    <div style={{ marginBottom: '5px' }}>
                                        <label style={{ fontWeight: 'bold', marginRight: '10px' }}>Access: </label>
                                    </div>
                                    <select
                                        as="select"
                                        value={access_Ids}
                                        onChange={onAccessIdChanged}
                                        multiple
                                        className="new-report-select"
                                        title="Give access"
                                        style={{
                                            overflowX: 'hidden',
                                            overflowY: 'scroll',
                                            scrollbarWidth: 'thin',
                                            border: '2px solid #ccc',
                                        }}
                                    >
                                        {options}
                                    </select>
                                </div>
                            )}


                            {/* {(!isAdmin && !isManager && access_Ids.length > 0) && (
                                <>
                                    <div style={{ marginBottom: '5px' }}>
                                        <label style={{ fontWeight: 'bold', marginRight: '10px', marginLeft: '5px' }}>Access: </label>
                                    </div>
                                    <ul className="access-list">
                                        {access_Ids.map((id, index) => (
                                            <li key={index}>{id}</li>
                                        ))}
                                    </ul>
                                </>
                            )} */}

                            {/* {(!isAdmin && !isManager && !access_Ids.length) && (
                                <div style={{ marginTop: '5px' }}>
                                    <p><strong>Access: N/A</strong></p>
                                </div>
                            )} */}

                            {((completed === "false" || completed === "Read" || completed === "Final") && (isAdmin || isManager || isQA)) && (
                                <div style={{ marginTop: "10px" }}>
                                    <div
                                        style={{
                                            display: "grid",
                                            gridTemplateColumns: "repeat(auto-fit, minmax(180px, 1fr))",
                                            gap: "10px",
                                            alignItems: "center",
                                            justifyContent: "start"
                                        }}
                                    >
                                        <label style={{ display: "flex", alignItems: "center", gap: "10px", fontWeight: "bold" }}>
                                            <input
                                                type="checkbox"
                                                checked={allChecked}
                                                ref={input => input && (input.indeterminate = someChecked && !allChecked)}
                                                onChange={handleSelectAll}
                                            />
                                            All Checked
                                        </label>

                                        {Object.keys(checks).map((key, index) => (
                                            <label key={index} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                <input
                                                    type="checkbox"
                                                    name={key}
                                                    checked={checks[key]}
                                                    onChange={handleChange}
                                                />
                                                {key
                                                    .replace(/ModalityInstitute/g, "Modality & Institute")
                                                    .replace(/GrammarSpelling/g, "Grammar & Spelling")
                                                    .replace(/PACSUpload/g, "PACS Upload")
                                                    .replace(/([a-z])([A-Z])/g, "$1 $2")}
                                            </label>
                                        ))}
                                    </div>
                                </div>
                            )}

                            <br />

                            <label style={{ fontWeight: 'bold', marginRight: '5px', marginBottom: '10px' }}>
                                WORK COMPLETED:
                                <div className="checkbox-wrapper-31">
                                    <input
                                        id="report-completed"
                                        name="completed"
                                        type="checkbox"
                                        title={!allChecked ? "Complete all checks to enable" : "Mark completed"}
                                        checked={completed === "true"}
                                        disabled={!allChecked}
                                        onChange={onCompletedChanged}
                                        style={{
                                            cursor: allChecked ? "pointer" : "not-allowed",
                                        }}
                                    />
                                    <svg viewBox="0 0 24 24">
                                        <circle className="background" cx="12" cy="12" r="12"></circle>
                                        <circle className="stroke" cx="12" cy="12" r="9.6"></circle>
                                        <polyline className="check" points="7.92 12.08 10.4 14.82 16.8 8.58"></polyline>
                                    </svg>
                                </div>
                            </label>

                            <br />

                            <button className="new-report-button" title="Save report" style={{ marginRight: '20px' }} type="submit" disabled={isLoading}>
                                <FontAwesomeIcon icon={faSave} /> {isLoading ? 'Saving...' : ``}
                            </button>

                            <button className="new-report-button" title="Approve report" style={{ color: '#47ff5dcd', marginRight: '20px' }} onClick={onApproveReportClicked} disabled={isLoading}>
                                <FontAwesomeIcon icon={faCheck} />
                            </button>

                            <button className="new-report-button" title="Addendum report" style={{ color: '#ffbc47cd', marginRight: '20px' }} onClick={onAddendumReportClicked} disabled={isLoading}>
                                <FontAwesomeIcon icon={faPen} />
                            </button>

                            {(isAdmin || isManager || isQA) && (
                                <button className="new-report-button" title="Final report" style={{ color: '#5e7ea8', marginRight: '20px' }} onClick={onFinalReportClicked} disabled={isLoading}>
                                    <FontAwesomeIcon icon={faCircleRight} />
                                </button>)
                            }

                            {deleteButton}

                        </form>
                    </Col>

                    <Col xs={12} md={8}>

                        <div style={{ display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap', color: '#717378cd' }}>
                            <div style={{ marginRight: '10px' }}>
                                <label style={{ fontWeight: 'bold' }}>Accession No: {report.accessionno}</label>
                                <span style={{ margin: '0 5px' }}>|</span>
                            </div>
                            <div style={{ marginRight: '10px' }}>
                                <label style={{ fontWeight: 'bold' }}>
                                    status: {report.completed === 'true' ? 'Completed' : report.completed === 'false' ? 'Open' : report.completed}
                                </label>
                                <span style={{ margin: '0 5px' }}>|</span>
                            </div>
                            <div style={{ marginRight: '10px' }}>
                                <label style={{ fontWeight: 'bold' }}>Assigned by: {report.originator}</label>
                                <span style={{ margin: '0 5px' }}>|</span>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap', color: '#717378cd' }}>
                            <div style={{ marginRight: '10px' }}>
                                <label style={{ fontWeight: 'bold' }}>Created: {created}</label>
                                <span style={{ margin: '0 5px' }}>|</span>
                            </div>
                            <div style={{ marginRight: '10px', marginBottom: '5px' }}>
                                <label style={{ fontWeight: 'bold' }}>Updated: {updated}</label>
                                <span style={{ margin: '0 5px' }}>|</span>
                            </div>
                        </div>

                        {(sopaccess || sopaccessmanager || isAdmin) && selectedInstitute && (
                            <div style={{
                                background: '#ffffff',
                                border: '2px solid #ccc',
                                padding: '10px',
                                borderRadius: '8px',
                                boxShadow: '2px 2px 8px rgba(0,0,0,0.1)',
                                marginBottom: '5px',
                                marginTop: '3px',
                                fontSize: '14px',
                                color: 'black',
                                width: '100%',
                                maxHeight: '120px', // Allows expansion if needed
                                overflowY: 'auto', // Enables scrolling for long text
                            }}>
                                {((sopaccess && sopaccessmanager) || isAdmin) ? (
                                    <>
                                        {/* Manager SOP */}
                                        {selectedInstituteSOPManager ? (
                                            <>
                                                <div style={{ marginTop: '2px', paddingRight: '5px', marginLeft: '4px' }}>
                                                    {formatSOP(selectedInstituteSOPManager, "Institute SOP [Manager]: ")}
                                                </div>
                                            </>
                                        ) : (
                                            <p><strong>Institute SOP [Manager]:</strong> No SOP available.</p>
                                        )}

                                        {/* Consultant SOP */}
                                        {selectedInstituteSOP ? (
                                            <>
                                                <div style={{ marginTop: '2px', paddingRight: '5px', marginLeft: '4px' }}>
                                                    {formatSOP(selectedInstituteSOP, "Institute SOP [Consultant]: ")}
                                                </div>
                                            </>
                                        ) : (
                                            <p><strong>Institute SOP [Consultant]:</strong> No SOP available.</p>
                                        )}
                                    </>
                                ) : sopaccess && selectedInstituteSOP ? (
                                    <>
                                        <div style={{ marginTop: '2px', paddingRight: '5px', marginLeft: '4px' }}>
                                            {formatSOP(selectedInstituteSOP, "Institute SOP [Consultant]: ")}
                                        </div>
                                    </>
                                ) : sopaccessmanager && selectedInstituteSOPManager ? (
                                    <>
                                        <div style={{ marginTop: '2px', paddingRight: '5px', marginLeft: '4px' }}>
                                            {formatSOP(selectedInstituteSOPManager, "Institute SOP [Manager]: ")}
                                        </div>
                                    </>
                                ) : null}
                            </div>
                        )}


                        {/* <div style={{ marginBottom: '5px' }}>
                            <label style={{ fontWeight: 'bold', marginRight: '10px', marginLeft: '5px' }}>Report: </label>
                        </div> */}
                        <ReactQuill theme="snow" value={saveReports}
                            onChange={handleQuillChange}
                            modules={modules}
                            ref={quillRef}
                            className='react-quill'
                            title="Report editor"
                            style={{ background: 'white', borderRadius: '5px', border: '2px solid #ccc', color: 'black' }}
                        />

                        <div style={{ marginTop: '5px', marginBottom: '10px' }}>

                            <button onClick={listening ? stopListening : startListening} title="Microphone" className={`mic-btn ${listening ? 'listening' : ''}`}>
                                {listening ?
                                    <FontAwesomeIcon icon={faMicrophone} /> :
                                    <FontAwesomeIcon icon={faMicrophoneSlash} />
                                }
                            </button>

                            <label className="auto-start-button">
                                <input type="checkbox" title="Auto start mic" onClick={() => setIsAutoListening(!isAutoListening)} />
                                <span className="slider round"></span>
                            </label>

                            <textarea
                                ref={textAreaRef}
                                value={handleTranscriptChange(transcript)}
                                title="Transcript text"
                                readOnly
                                style={{
                                    width: '80%',
                                    marginLeft: '10px',
                                    resize: 'none',
                                    textDecoration: 'none',
                                    height: '30px',
                                    borderRadius: '5px',
                                    verticalAlign: 'middle'
                                }}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
            <Modal1 show={showModal} onClose={handleCloseModal}>
                <p>{errMsg}</p>
            </Modal1>

            <button
                className="chat-btn"
                title="Open chat"
                onClick={openChat}
            >
                <FontAwesomeIcon icon={faComment} />
            </button>
            <Modal
                isOpen={isChatOpen}
                onRequestClose={closeChat}
                contentLabel="Chat Modal"
                ariaHideApp={false}
                className='modal1'
            >
                <ChatBox closeChat={closeChat} />
            </Modal>

        </>
    )

    return content
}

export default EditReportForm


// import { saveAs } from 'file-saver';
// import { pdfExporter } from 'quill-to-pdf';
// import htmlDocx from 'html-docx-js/dist/html-docx';


// const exportToPdf = async () => {
//     const quillInstance = quillRef.current.getEditor();
//     const delta = quillInstance.getContents();
//     const pdfAsBlob = await pdfExporter.generatePdf(delta);
//     saveAs(pdfAsBlob, `pdf_${report._id}.pdf`);
// }

// const exportToDocx = () => {
//     const quillInstance = quillRef.current.getEditor();
//     const html = quillInstance.root.innerHTML;
//     const converted = htmlDocx.asBlob(html);
//     saveAs(converted, `docx_${report._id}.docx`);
// }


/* 
<button className="btn2" onClick={exportToPdf}>
    PDF
</button>

<button className="btn2" onClick={exportToDocx}>
    DOCX
</button>
*/
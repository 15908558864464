const DashFooter = () => {
    const content = (
        <footer className='footer d-flex justify-content-between p-3 shadow-lg mx-3 fixed-bottom'>
            <p className='m-0'>report module V2.5.0</p>
            <p className='m-0'>rappin V1.0 powered by telerapp</p>
        </footer>
    )
    return content
}

export default DashFooter
